// Need to disable this rule to keep signature
/* eslint-disable class-methods-use-this */

import { SdkMessageType, SdkMessageCategory } from 'sdk-ui-web-library-common'
import {
  addIframeModal, addIframeModalStyle, createIFrameElement, iframeBaseId, iframeWrapperId,
} from './iframeHelper'
import IdentityWindow from '../identityWindow'
import { getNativeBridge } from '../nativeBridge'

export default class IdentityFrame extends IdentityWindow {
  constructor(props = {}) {
    super(props)
    this.isMessagingEnabled = false
    this.url = null
    this.registrationReferrer = null
    this.referringPage = null

    if (props.onIframeLoad) this.onIframeLoadCallback = props.onIframeLoad

    // make 'this' always refer to IdentityFrame, regardless of where onMessage is called from
    this.onMessage = this.onMessage.bind(this)
    this.resizeIframe = this.resizeIframe.bind(this)
  }

  enableMessaging() {
    super.enableMessaging()
    this.isMessagingEnabled = true
  }

  disableMessaging() {
    super.disableMessaging()
    this.isMessagingEnabled = false
  }

  getWindow() {
    const { iframe } = this
    return iframe?.contentWindow
  }

  get iframeWrapper() {
    return document.getElementById(iframeWrapperId)
  }

  get iframe() {
    return document.getElementById(iframeBaseId)
  }

  openIframe(url) {
    if (!this.isMessagingEnabled) this.enableMessaging()

    this.url = url
    this.loadIframeStyling().then(() => {
      const iframe = addIframeModal(url)
      iframe.onload = () => {
        // wrap in timeout because on some browsers, iframe's
        // onload will get called before iframe's UI is drawn
        setTimeout(() => {
          // For versions below Safari 14 we must use addListener()
          // to observe media query lists
          try {
            matchMedia('(min-width: 600px)').addEventListener(
              'change',
              this.resizeIframe,
            )
          } catch (err) {
            if (err instanceof TypeError) {
              matchMedia('(min-width: 600px)').addListener(
                this.resizeIframe,
              )
            }
          }

          this.iframeWrapper.style.backgroundColor = ''
          if (this.onIframeLoadCallback) this.onIframeLoadCallback()
        }, 50)
      }
      // don't show background overlay until iframe is displayed
      this.iframeWrapper.style.backgroundColor = 'transparent'
    })
  }

  /**
     * Load Iframe styling on demand
     *
     * @return {Promise}
     */
  loadIframeStyling() {
    return new Promise((resolve) => {
      const themeConfig = this.config?.theme ?? {}
      addIframeModalStyle(themeConfig).then(resolve)
    })
  }

  getIframe(url) {
    if (!this.isMessagingEnabled) this.enableMessaging()

    const iframe = createIFrameElement(url)
    iframe.onload = () => {
      // wrap in timeout because on some browsers, iframe's
      // onload will get called before iframe's UI is drawn
      setTimeout(() => {
        if (this.onIframeLoadCallback) this.onIframeLoadCallback()
      }, 50)
    }
    return iframe
  }

  onMessage(event) {
    if (event.data.type && event.data.category === SdkMessageCategory.REQUEST &&
            event.origin === this.targetOrigin) {
      if (event.data.type === SdkMessageType.RESIZE) {
        const { width, height } = event.data.value
        this.width = width
        this.height = height
        this.resizeIframe()
      }

      if (event.data.type === SdkMessageType.CLOSE) {
        this.closeIframe()
      }
    }
    super.onMessage(event)
  }

  resizeIframe() {
    const div = document.getElementById('identity-window-iframe-modal')
    if (div) {
      const mediaQueryList = matchMedia('(min-width: 600px)')
      if (mediaQueryList.matches) {
        div.style.setProperty('width', `${this.width}px`)
        div.style.setProperty('height', `${this.height}px`)
      } else {
        div.style.setProperty('width', '100vw')
        div.style.setProperty('height', '100vh')
      }
    }
  }

  closeIframe() {
    document.body.style.overflow = ''
    const iframeWrapper = document.getElementById(iframeWrapperId)
    if (iframeWrapper) iframeWrapper.remove()

    // For versions below Safari 14 we must use removeListener() to observe media query lists
    try {
      matchMedia('(min-width: 600px)').removeEventListener(
        'change',
        this.resizeIframe,
      )
    } catch (err) {
      if (err instanceof TypeError) {
        matchMedia('(min-width: 600px)').removeListener(this.resizeIframe)
      }
    }

    if (this.native) {
      // Send close webview command
      getNativeBridge().then((bridge) => {
        if (!bridge) {
          // eslint-disable-next-line no-console
          console.error('Bridge not initialized')
          return
        }

        bridge.sendCommand('FrameClose')
      })
    }
  }
}
